html,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: subpixel-antialiased !important;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@media (min-width: 769px) {
  html {
    overflow: hidden;
    height: 100%;
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto Condensed', sans-serif;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 15px;
  color: #333;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  transition: width 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(155, 154, 154, 0.4);
}

.application {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bg-primary {
  background-color: #8b0305 !important;
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.54);
}

.bg-secondary-light {
  background-color: rgba(0, 0, 0, 0.04);
}

.btn-primary {
  background-color: #8b0305 !important;
  border: 1px solid #8b0305 !important;
}

.text-primary {
  background-color: #8b0305 !important;
}

button:focus {
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiAlert-icon {
  align-items: center;
  justify-content: center;
}

.primary-header .dx-row.dx-header-row {
  background-color: #8b0305;
  color: #fff;
}

.dx-texteditor.dx-editor-outlined {
  background-color: #dedede;
  box-shadow: none;
}

.dx-selectbox .dx-texteditor-input,
.dx-datebox .dx-texteditor-input {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 7px;
  padding-left: 0.75em !important;
}

.dx-datagrid-table .dx-selectbox .dx-texteditor-input,
.dx-datagrid-table .dx-datebox .dx-texteditor-input {
  border: none;
}

.dx-selectbox .dx-placeholder,
.dx-datebox .dx-texteditor-input {
  font-size: 0.8rem;
}

.customDialog .dx-selectbox .dx-placeholder,
.customDialog .dx-datebox .dx-texteditor-input,
.dx-popup-normal .dx-selectbox .dx-placeholder,
.dx-popup-normal .dx-datebox .dx-texteditor-input {
  left: 1em;
}

.dx-selectbox.dx-state-hover,
.dx-selectbox.dx-state-focused,
.dx-datebox.dx-state-hover,
.dx-datebox.dx-state-focused {
  box-shadow: none !important;
}

.dx-selectbox.dx-texteditor.dx-editor-outlined,
.dx-datebox.dx-texteditor.dx-editor-outlined {
  background-color: transparent !important;
}

.dx-textbox {
  line-height: 0;
  font-size: 14px;
}

.dx-popup-bottom.dx-toolbar {
  padding: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  left: 50%;
  transform: translateX(-50%);
  will-change: transform;
  padding-left: 0;
  direction: ltr;
  padding-bottom: 10px;
}

.dx-dropdowneditor-overlay .dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding-bottom: 0;
}

.dx-toolbar-button .dx-button-has-text[aria-label='Save'] .dx-button-content,
.dx-toolbar-button .dx-button-has-text[aria-label='OK'] .dx-button-content,
.dx-toolbar-button .dx-button-has-text[aria-label='Yes'] .dx-button-content {
  border-radius: 7px;
  background-color: #8b0304;
  max-width: 10em;
  transition: all 500ms ease-in-out;
}

.dx-toolbar-button
  .dx-button-has-text[aria-label='Save']
  .dx-button-content:hover,
.dx-toolbar-button
  .dx-button-has-text[aria-label='OK']
  .dx-button-content:hover,
.dx-toolbar-button
  .dx-button-has-text[aria-label='Yes']
  .dx-button-content:hover {
  border-radius: 7px;
  background-color: #b80304;
  max-width: 10em;
  transition: all 500ms ease-in-out;
}

.dx-toolbar-button .dx-button-has-text[aria-label='Cancel'] .dx-button-content,
.dx-toolbar-button .dx-button-has-text[aria-label='No'] .dx-button-content {
  border-radius: 7px;
  background-color: #999;
  max-width: 10em;
  transition: all 500ms ease-in-out;
}

.dx-toolbar-center {
  margin: auto !important;
  float: none !important;
}

.dx-toolbar-button
  .dx-button-has-text[aria-label='Cancel']
  .dx-button-content:hover,
.dx-toolbar-button
  .dx-button-has-text[aria-label='Yes']
  .dx-button-content:hover {
  border-radius: 7px;
  background-color: #bbb;
  max-width: 10em;
  transition: all 500ms ease-in-out;
}

.dx-toolbar-button .dx-button-text {
  font-size: 0.8rem !important;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.dx-popup-content .dx-texteditor-container {
  overflow: visible;
}

.dx-popup-content .dx-textbox textarea {
  padding: 10px !important;
  height: 8.5em;
  width: 100%;
  border-radius: 7px;
  font-size: 0.9rem;
  border: 1px #e5e5e5 solid;
  transition: all 500ms ease-in-out;
  box-shadow: 0 0 0px rgba(0 0 0 / 0);
  word-break: break-word;
  line-height: 1.25;
}

.dx-popup-content .dx-textbox.dx-texteditor input {
  padding: 10px !important;
  border: 1px #e5e5e5 solid;
  border-radius: 7px;
  transition: all 500ms ease-in-out;
  box-shadow: 0 0 0px rgba(0 0 0 / 0);
}

.dx-popup-content label,
.dx-popup-content label span {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.5em;
  line-height: 1;
}

.dx-texteditor.dx-editor-underlined::after,
.dx-texteditor.dx-editor-underlined::before {
  display: none;
}

.dx-popup-content .dx-textbox.dx-state-focused textarea,
.dx-popup-content .dx-textbox.dx-state-focused input {
  box-shadow: 0 0 15px rgba(0 0 0 / 20%);
  outline: none;
  transition: all 500ms ease-in-out;
}

.dx-state-hover::not(.dx.state.focused) {
  box-shadow: none !important;
}

.dx-icon.dx-icon-clear {
  background: none;
}

.dx-datagrid-search-panel {
  width: 250px;
  padding: 0 1em;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0 0 0 / 10%);
  border-radius: 20px;
}

.dx-datagrid-search-panel input {
  padding: 0.5em 2em !important;
}

.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending):last-of-type,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all):last-of-type,
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td:not(.dx-validation-pending):not(.dx-datagrid-select-all):last-of-type {
  text-align: center;
}

.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-cell-modified:not(.dx-field-item-content):not(.dx-validation-pending),
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-invalid:not(.dx-field-item-content):not(.dx-validation-pending):not(.dx-datagrid-select-all),
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td:not(.dx-validation-pending):not(.dx-datagrid-select-all) {
  white-space: pre-line;
}

.bZqigJ .accordion .content .smallTextField {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #999999 !important;
  text-transform: uppercase !important;
}

.iWXHkd .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.gqcfyu {
  font-weight: bold;
}

.doOCnu {
  font-weight: bold !important;
}

.header-name {
  font-weight: bold !important;
}

.dx-list-item-content {
  font-weight: bold;
  font-size: 0.75rem;
}

.customDialogBG {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0 0 0 / 15%);
  width: 100%;
  height: 100%;
  z-index: 600;
}

.customDialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 85%;
  height: 85%;
  z-index: 700;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  padding: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
}

.customDialog h2 {
  color: #333;
  font-weight: 700;
  letter-spacing: 0.01em;
  font-size: 1.5rem;
  margin-top: 0;
}

.customDialog .dx-toolbar-items-container {
  height: 30px;
}

/* .customDialog .dx-icon-edit-button-addrow {
  z-index: 10;
  position: absolute;
} */

.dx-datagrid-header-panel {
  padding: 0;
}

.dx-datagrid-header-panel .dx-toolbar-after {
  direction: ltr;
}

.dx-datagrid-header-panel
  .dx-datagrid-addrow-button
  .dx-icon-edit-button-addrow::before {
  color: #8b0304;
}

.dx-datagrid-headers {
  margin-top: 20px;
}

.customDialog .dx-datagrid {
  background-color: initial;
}

.customDialog .dx-datagrid .statuscell {
  background-color: #333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: auto;
  position: relative;
}

.customDialog .dx-datagrid .statuscell svg {
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

.customDialog .dx-datagrid .statuscell svg.closure {
  transform: translate(calc(-50% + 1px), -50%);
  will-change: transform;
}

.customDialog .dx-datagrid .statuscell svg.setup {
  transform: translate(calc(-50% - 1px), -50%);
  will-change: transform;
}

.customDialog .dx-datagrid-content.dx-datagrid-table {
  border-collapse: separate;
  border-spacing: 0;
}

.customDialog .dx-datagrid .dx-row-lines > td {
  border-bottom: 0 !important;
}

.customDialog .dx-datagrid-headers.dx-datagrid-nowrap,
.customDialog .dx-datagrid-header-panel {
  border: none;
}

.customDialog .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border: none;
}

.customDialog .dx-datagrid-rowsview {
  overflow: visible;
}

.customDialog .dx-datagrid-table tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.customDialog .dx-datagrid-table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.customDialog .dx-row {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 7%);
  border: none !important;
}
.customDialog .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  padding-top: 0;
}

.customDialog .dx-row.dx-header-row {
  background: transparent;
  box-shadow: none;
  border: none;
}

.customDialog .grid {
  margin-top: 40px !important;
  width: 95%;
  margin: 0 auto;
}
.customDialog .grid .dx-datagrid-header-panel,
.customDialog .grid .dx-toolbar-items-container {
  background-color: #f5f5f5;
}

.customDialog .grid .dx-datagrid-search-panel {
  width: 35em !important;
  margin-right: 25vw;
  border: 2px solid #aeaeae;
  border-radius: 0px;
  border-bottom: 0;
  background-color: white;
}

.dx-searchbox .dx-texteditor-container {
  direction: ltr;
}

.dx-searchbox.dx-editor-underlined .dx-placeholder::before {
  padding-left: 35px;
  font-size: 1rem;
}
.customDialog .grid input {
  margin-left: 3%;
  outline: none;
}

.customDialog .grid .dx-row-lines {
  cursor: pointer;
}
.customDialog .grid .dx-datagrid-action {
  font-weight: bold;
  color: #bbbbbb;
  background-color: none;
}

.customDialog .closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 800;
}

.tdev label {
  text-transform: none;
  font-weight: 300;
  color: #333;
  line-height: 1.25;
  letter-spacing: 0;
  margin-bottom: 1em;
  margin-top: 2em;
}

.dx-texteditor-buttons-container {
  background: #fff;
}

.jtIkiJ .dx-datagrid,
.jtIkiJ .dx-datagrid-header-panel,
.jtIkiJ .dx-toolbar {
  background-color: #ffffff !important;
}

/* .dx-toolbar-after,
.dx-toolbar-before,
.dx-toolbar-center {
  direction: rtl;
} */

.PrivateRadioButtonIcon-root-5 {
  color: #666 !important;
}

.PrivateRadioButtonIcon-root-12 {
  color: #666 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #8b0304 !important;
}

.PrivateRadioButtonIcon-root-5.PrivateRadioButtonIcon-checked-7 {
  color: #8b0304 !important;
}

.PrivateRadioButtonIcon-root-5 {
  font-size: 1rem !important;
}

.MuiSvgIcon-root {
  font-size: 1rem !important;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 0.9rem !important;
  font-family: 'Roboto Condensed', sans-serif;
}

.css-hlj6pa-MuiDialogActions-root {
  padding: 8px 24px !important;
}
